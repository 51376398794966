<template>
  <div>
    <h3><span class="span-icon"></span>证书查询</h3>
    <!-- 查询条件 -->
    <div class="filter_wrap">
      <div class="container_wrap">
        <el-radio-group v-model="tabType" @change="changeQuery" style="margin-bottom:20px;">
          <el-radio-button label="certificate">证书编号</el-radio-button>
          <el-radio-button label="id_no">身份证号</el-radio-button>
        </el-radio-group>
        <el-form
          :inline="true"
          :model="queryForm"
          ref="applyForm"
          :rules="rules"
          @submit.native.prevent>
          <el-form-item :prop="tabType">
            <el-input v-model.trim="queryForm.id_no" placeholder="请输入身份证号" v-if="tabType == 'id_no'" style="width:380px;" />
            <el-input v-model.trim="queryForm.certificate" placeholder="请输入证书编号"  v-if="tabType == 'certificate'" style="width:380px;" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="certificateQuery('applyForm')">立即查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 查询结果 -->
    <div class="container_wrap" v-loading="loading">
      <template v-if="certList_total > 0">
        <div class="tip">已查询到<span>{{certList_total}}</span>项证书。</div>
        <el-row :gutter="30">
          <el-col :span="8" v-for="item in certList" :key="item.id">
            <el-card :body-style="{ padding: '40px 30px', zIndex: 1, position: 'relative' }" class="card_item_common">
              <h3 class="text_ellipsis" :title="item.certificate.name">{{ item.certificate.name }}</h3>
              <p>姓名：{{ item.real_name }}</p>
              <p>身份证号：{{ item.identity_no }}</p>
              <p>证书编号：{{ item.serial_no }}</p>
              <p>发证日期：{{ item.created_at }}</p>
            </el-card>
          </el-col>
        </el-row>
      </template>
      <el-empty description="抱歉，未查询到相关证书" v-if="certList_total == 0 && first == false"></el-empty>
    </div>
  </div>
</template>

<script>
  import { checkCertificate } from 'api/certificate/detail'
  import { checkIDCardNo, checkcertificateNum } from 'utils/common.js'
  import { showMessage } from 'api/base/message'
  export default {
    components: {

    },
    data() {
      return {
        first: true,
        tabType: 'certificate',
        queryForm: {
          id_no: '',
          certificate:''
        },
        queryFormPlaceholder: '请输入证书编号',
        loading: false,
        certList: [],
        certList_total:'',
        rules:{
          id_no: [
            {required: true, validator: checkIDCardNo, trigger: 'blur'},
          ],
          certificate: [
            {required: true, validator: checkcertificateNum, trigger: 'blur'},
          ],
        }
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      changeQuery(name) {
      if(name === 'certificate') {
          this.queryForm.id_no = ''
          this.certList = []
          this.first = true
          this.certList_total = ''
          this.$refs["applyForm"].clearValidate()
        } else {
          this.queryForm.certificate = ''
          this.certList = []
          this.first = true
          this.certList_total = ''
          this.$refs["applyForm"].clearValidate()
        }
      },
      certificateQuery(formName){
        this.$refs[formName].validate((valid) => {
          if(valid){
            const filters = {
                'certificate':this.queryForm.certificate,
                'id_no':this.queryForm.id_no}
            this.loading = true
            checkCertificate(filters).then((result) => {
              this.first = false
              this.loading = false
              if(result.success == true){
                  this.certList = result.data
                  this.certList_total = result.total
              }else{
                this.certList = []
                this.certList_total = 0
              }
            })
          }else {
           return false;
          }
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
h3 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 20px;
  font-size: 18px;
  .span-icon {
    position: relative;
    top: 2px;
    margin-right: 7px;
    width: 4px;
    height: 18px;
    background: #009DD9;
    display: inline-block;
  }
}
.card_item_common {
  border:none;
  border-radius:10px;
  -webkit-box-shadow: 2px 2px 8px rgba(0,0,0,0.08);
  box-shadow: 2px 2px 8px rgba(0,0,0,0.08);
  position: relative;
  overflow:hidden;
  h3 {
    margin:0;
    padding-bottom:20px;
    font-size:20px;
  }
  p {
    margin-bottom:0;
    margin-top:15px;
    font-size:12px;
    color:#666;
  }
  &:before {
    content:'';
    width:126px;
    height:129px;
    background:url("~@/assets/img/cert_bg1.png") no-repeat;
    position: absolute;
    left:-51px;
    top:-54px;
    z-index:0;
  }
  &:after {
    content:'';
    width:150px;
    height:152px;
    background:url("~@/assets/img/cert_bg2.png") no-repeat;
    position: absolute;
    right:-33px;
    bottom:-36px;
    z-index:0;
  }
}
.tip {
  margin-bottom:20px;
  > span {
    color:#ff6600;
    margin-left:2px;
    margin-right:2px;
  }
}
</style>
